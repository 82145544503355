<template>
  <div>
    <div class="flex items-center pt-8 pb-4">
      <span class="mr-auto text-lg font-medium truncate ml-3 font-bold">商户管理</span>
      <div class="flex items-center">
        <button class="button mr-3 bg-theme-1 text-white" @click="init(true)">
          <i class="el-icon-refresh-left mr-1"></i>
          <span>刷新</span>
        </button>

        <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#newBusinesses"
          class="button inline-block bg-theme-1 mr-3 text-white"
        >
          <i class="el-icon-plus mr-1"></i> <span>新建</span></a
        >

        <button class="button mr-3 bg-theme-6 text-white" @click="onBatchDetele">
          <i class="el-icon-delete mr-1"></i>
          <span>批量删除</span>
        </button>
      </div>
    </div>

    <div class="report-box zoom-in box p-4 intro-y" style="cursor: auto">
      <el-form
        ref="ruleForm"
        :inline="true"
        :model="formData"
        class="flex items-center"
        style="width: 100%"
        size="small"
      >
        <div class="flex pt-4 pb-4" style="justify-content: space-between; width: 100%">
          <!-- <div> -->
          <!-- <el-form-item label="商家名称" prop="merchant_name">
            <el-select v-model="formData.merchant_name" placeholder="全部">
              <el-option
                v-for="item in app_list"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item> -->

          <el-form-item label="商家名称" prop="merchant_name">
            <el-input v-model="formData.merchant_name" placeholder="商家名称"></el-input>
          </el-form-item>

          <el-form-item label="商家手机号" prop="merchant_phone">
            <el-input
              v-model="formData.merchant_phone"
              placeholder="商家手机号"
            ></el-input>
          </el-form-item>

          <el-form-item label="状态" prop="state">
            <el-select v-model="formData.state" placeholder="全部">
              <el-option
                v-for="item in state_set"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="备注" prop="remarks">
            <el-input v-model="formData.remarks" placeholder="备注"></el-input>
          </el-form-item>
          <!-- </div> -->
          <!-- <div class="ml-auto"> -->
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="onSearch"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh-right" @click="resetForm('ruleForm')"
              >重置</el-button
            >
          </el-form-item>
          <!-- </div> -->
        </div>
      </el-form>

      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="merchant_name" label="商户名称"> </el-table-column>
        <el-table-column prop="merchant_phone" label="商家手机号"> </el-table-column>
        <el-table-column prop="state" label="状态">
          <template slot-scope="scope">
            <div :class="scope.row.state === '1' ? 'text-theme-9' : 'text-theme-6'">
              {{ scope.row.state === "1" ? "启用" : "禁用" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="remarks" label="备注"> </el-table-column>
        <el-table-column prop="created_at" label="创建日期"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span class="mr-6 text-theme-1" @click="handleClick(scope.row)">编辑</span>
            <span class="text-theme-6" @click="handleDetele(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>

      <div class="flex mt-10">
        <el-pagination
          :current-page.sync="paginate.p"
          :page-size="paginate.limit"
          layout="total, prev, pager, next"
          :total="paginate.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <CashBox ref="CashBox" :app-list="app_list"></CashBox>
  </div>
</template>

<script>
import request from "@/utils/request";
import CashBox from "./components/Cashbox.vue";
export default {
  components: {
    CashBox,
  },
  data() {
    return {
      tableData: [],
      tableLoading: true,
      app_list: [], // 商家列表
      state_set: [], // 状态列表
      paginate: {},
      formData: {
        merchant_name: "",
        merchant_phone: "",
        remarks: "",
        state: "",
        p: "1",
        size: "10",
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    handleClick(data) {
      cash("#newBusinesses").modal("show");
      this.$refs["CashBox"].formData = data;
    },

    init(refresh = false) {
      this.tableLoading = true;
      request({
        url: "/merchant/merchant/getMerchantList",
        method: "GET",
        data: this.formData,
      }).then((res) => {
        this.tableLoading = false;
        this.tableData = res.data.list;
        this.app_list = res.data.app_list;
        this.state_set = res.data.state_set;
        this.paginate = res.data.paginate;

        if (refresh) {
          this.$message.success("刷新成功");
        }
      });
    },
    // 搜索
    onSearch() {
      this.init();
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.init(true);
    },
    // 单个删除
    handleDetele(data) {
      this.$confirm("此操作将永久删除该商户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request({
            url: "/merchant/merchant/delMerchant",
            method: "POST",
            data: {
              ids: [data.id],
            },
          }).then((res) => {
            if (res.code === 0) {
              this.$message.success("删除成功");
              this.init();
            } else {
              console.error("未知错误：" + res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 批量删除
    onBatchDetele() {
      let selection = this.$refs["multipleTable"].selection; // 获取选中数据

      request({
        url: "/merchant/merchant/delMerchant",
        method: "POST",
        data: {
          ids: selection.map((item) => {
            return item.id;
          }),
        },
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success("删除成功");
          this.init();
        } else {
          console.error("未知错误：" + res.msg);
        }
      });
    },

    handleSizeChange() {},
    handleCurrentChange() {},
  },
};
</script>

<style lang="scss" scoped></style>
